// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-article-full-tsx": () => import("./../../../src/page-templates/article-full.tsx" /* webpackChunkName: "component---src-page-templates-article-full-tsx" */),
  "component---src-page-templates-clause-chronology-tsx": () => import("./../../../src/page-templates/clause-chronology.tsx" /* webpackChunkName: "component---src-page-templates-clause-chronology-tsx" */),
  "component---src-page-templates-clause-full-tsx": () => import("./../../../src/page-templates/clause-full.tsx" /* webpackChunkName: "component---src-page-templates-clause-full-tsx" */),
  "component---src-page-templates-clause-main-tsx": () => import("./../../../src/page-templates/clause-main.tsx" /* webpackChunkName: "component---src-page-templates-clause-main-tsx" */),
  "component---src-page-templates-clause-parts-tsx": () => import("./../../../src/page-templates/clause-parts.tsx" /* webpackChunkName: "component---src-page-templates-clause-parts-tsx" */),
  "component---src-page-templates-index-year-tsx": () => import("./../../../src/page-templates/index-year.tsx" /* webpackChunkName: "component---src-page-templates-index-year-tsx" */),
  "component---src-page-templates-simple-about-tsx": () => import("./../../../src/page-templates/simple/about.tsx" /* webpackChunkName: "component---src-page-templates-simple-about-tsx" */),
  "component---src-page-templates-simple-articles-tsx": () => import("./../../../src/page-templates/simple/articles.tsx" /* webpackChunkName: "component---src-page-templates-simple-articles-tsx" */),
  "component---src-page-templates-simple-clauses-tsx": () => import("./../../../src/page-templates/simple/clauses.tsx" /* webpackChunkName: "component---src-page-templates-simple-clauses-tsx" */),
  "component---src-page-templates-simple-faq-tsx": () => import("./../../../src/page-templates/simple/faq.tsx" /* webpackChunkName: "component---src-page-templates-simple-faq-tsx" */),
  "component---src-page-templates-simple-full-tsx": () => import("./../../../src/page-templates/simple/full.tsx" /* webpackChunkName: "component---src-page-templates-simple-full-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

